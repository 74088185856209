<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">评论列表</h3>
      <div class="shaixuan">
        <el-form ref="form" :model="screen" label-width="80px">
          <el-form-item label="用户名称">
            <el-input
              size="mini"
              v-model="screen.username"
              placeholder="请输入评论人名称查询"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品名称">
            <el-input
              size="mini"
              v-model="screen.goods_name"
              placeholder="请输入商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="时间">
            <el-date-picker
              size="mini"
              v-model="timeAll"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="类型">
            <el-select
              size="mini"
              v-model="screen.comment_type"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button type="primary" size="mini" @click="chongzhi">重置</el-button>
        <el-button type="primary" size="mini" @click="search">筛选</el-button>
      </div>
    </div>
    <el-table :data="tableData" height="600" style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="评论内容">
              <p>
                {{ props.row.content }}
              </p>
              <div class="demo-image">
                <div
                  class="block"
                  v-for="item in props.row.pic_list"
                  :key="item"
                  style="float: left;  margin-right: 10px"
                >
                  <el-image
                    @click="src_click(imgurl + item.url)"
                    v-if="item.type == 'image'"
                    style="width: 100px; height: 100px"
                    :src="imgurl + item.url"
                    :preview-src-list="srcList"
                    fit="contain"
                  ></el-image>
                  <video
                    style="width: 200px"
                    v-else
                    :src="imgurl + item.url"
                    controls="controls"
                  >
                    您的浏览器不支持 video 标签
                  </video>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="用户名称" prop="username"> </el-table-column>
      <el-table-column label="商品名称" prop="goods_name"> </el-table-column>
      <el-table-column label="评论时间" prop="addtime"> </el-table-column>
      <el-table-column class="commentNr" label="评论内容" prop="content">
      </el-table-column>
      <el-table-column label="是否追评">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.is_add_conment == 1">追加评论</el-tag>
          <el-tag v-else type="success">普通评论</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="状态显示">
        <template slot-scope="scope">
          <el-switch
            @change="ishide(scope.row)"
            v-model="scope.row.is_hide"
            :active-value="1"
            :inactive-value="2"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-button type="text">编辑</el-button> -->
          <el-button @click="del_click(scope.row.id)" type="text"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <span class="demonstration">直接前往</span> -->
      <el-pagination
        @current-change="crtChg"
        :current-page="screen.page"
        layout="total, prev, pager, next, jumper"
        :total="screen.total"
      >
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import timeTo from "@/utils/time.js";
import { get_comment_list, hide_comment, del_comment } from "@/api/api.js";
import { getSystemsettings } from "../../api/getSystemsetting.js";
export default {
  components: {},
  name: "",
  data() {
    return {
      fits: ["fill", "contain", "cover", "none", "scale-down"],
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      tableData: [],
      val: 1,
      srcList: [],
      imgurl: "",
      screen: {
        username: "",
        goods_name: "",
        start_time: "",
        end_time: "",
        comment_type: "",
        total: 0,
        size: 0,
        page: 1, //  页数
      },
      timeAll: [],
      options: [
        { value: 1, label: "真实评论" },
        { value: 2, label: "虚拟评论" },
      ],
      
    };
  },
  watch: {
    timeAll(newValue, oldValue) {
      if (newValue != null) {
        this.screen.start_time = Date.parse(new Date(newValue[0])) / 1000;
        this.screen.end_time = Date.parse(new Date(newValue[1])) / 1000;
      } else {
        this.screen.start_time = "";
        this.screen.end_time = "";
      }
    },
  },
  created() {
    this.get_comment();
    this.geturl();
  },
  mounted() {},
  methods: {
    chongzhi() {
      this.screen.username = "";
      this.screen.goods_name = "";
      this.screen.start_time = "";
      this.screen.end_time = "";
      this.screen.comment_type = "";
      this.screen.total = 0;
      this.screen.size = 0;
      this.screen.page = 1;
      this.get_comment();
    },
    search() {
      this.page = 1;
      this.total = 0;
      this.size = 0;
      this.get_comment();
    },
    geturl() {
      getSystemsettings.then((res) => {
        this.imgurl = res;
      });
    },
    //   图片预览
    src_click(img) {
      var arr = [];
      arr.push(img);
      this.srcList = arr;
    },
    // 切换分页
    crtChg(e) {
      this.screen.page = e;
      this.get_comment();
    },

    // 获取评论详情
    async get_comment() {
      const { data } = await get_comment_list(this.screen);
      if (data.code != 200) return this.$message.error(data.data);
      this.tableData = data.data.data;
      this.screen.total = data.data.total;
      this.screen.size = data.data.per_page;
      this.tableData.forEach((item) => {
        item.addtime = this.$timeTo.time3(item.addtime);
      });
    },

    //评论是否显示
    async ishide(row) {

      // row.is_hid = row.is_hid == 1 ? 2 : 1;
      console.log('row====',row)
      const { data } = await hide_comment({
        id: row.id,
        is_hide: row.is_hide,
      });
      if (data.code != 200) return this.$message.error(data.data);
      this.get_comment();
      // row.is_hid = row.is_hid == 1 ? 2 : 1;
      this.$message({
        message: "操作成功",
        type: "success",
      });
    },

    // 删除评论
    del_click(id) {
      this.$confirm("此操作将永久删除该评论, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await del_comment({ id });
          console.log(data);
          if (data.code != 200) return this.$message.error(data.data);
          this.$message({
            message: data.data,
            type: "success",
          });
          this.get_comment(this.val);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style>
.el-form {
  display: flex;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>